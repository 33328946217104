.drawer_item {
  padding: 10px 10px;
  font-size: 20px;
  margin: 5px 0;
}
.drawer_item:hover {
  background-color: #60cd96;
  color: #ffffff;
}
.active_p {
  background-color: #60cd96;
  color: #ffffff;
}
.HkMathListPage {
  .heading {
    margin-bottom: 3.5rem;
    margin-top: 40px;
    text-align: center;
    color: #10006d;
    font-size: 50px;
    line-height: 66px;
    span {
      color: #dd01ff;
    }
  }
  .teal {
    background-color: #20c997;
  }
  .orange {
    background-color: #f98f6f;
  }
  .purple {
    background-color: #9d87c3;
  }
  .cyan {
    background-color: #0dcaf0;
  }
  .button-280 {
    max-width: 280px;
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: #ffffff solid 3px;
    border-radius: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    transition: background 1s ease;
  }
  .button-280:hover {
    background-color: #60cd96;
    border-color: #60cd96;
  }
  .integrateWith {
    max-width: 1200px;
    margin: 0 auto;
  }
  .button {
    margin-top: 20px;
    width: 300px;
    padding: 20px 10px;
    background-color: #60cd96;
    text-align: center;
    font-size: 26px;
    border-radius: 50px;
    color: #fff;
    transition: background 0.5s ease; /* 关键过渡属性 */
  }
  .button:hover {
    background-color: #ffa726;
  }
  .head_title {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10%;
    background: #ffffff;
    box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 85px;
    z-index: 999;
    .head_icon {
      display: none;
      font-size: 40px;
      margin-right: 10px;
      z-index: 999;
    }
    img {
      width: 120px;
    }
    .head_item {
      display: flex;
    }
    .head_item span {
      border-bottom: 4px solid #fff;
      line-height: 55px;
      color: black !important;
      margin: 0 20px;
    }
    .active {
      border-color: #60cd96 !important;
      color: #60cd96 !important;
    }
    .head_item span:hover {
      border-color: #60cd96;
      color: #60cd96 !important;
    }
  }
  h3 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 2.5rem;
  }
  h6 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 1rem;
    font-weight: 700;
  }
  p {
    padding: 2rem 0 1.5rem;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .exeption {
    opacity: 0.8;
    margin-top: 5px;
  }
  h6 {
    font-size: 18px;
    line-height: 1.5;
  }
  h2 {
    font-size: 28px;
    line-height: 1.1;
    color: #29293a;
  }
  .title {
    margin-top: 11px;
    color: #ffffff;
    font-weight: 500;
  }
  .page {
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    padding-top: 85px;
    img {
      width: 100%;
    }
    .page_text {
      position: absolute;
      color: #ffffff;
      top: 30vh;
      margin-left: 10%;
      font-size: 40px;
      h2 {
        color: #fff;
        font-size: 40px;
        margin-bottom: 10px;
        font-weight: 700;
      }
      .title {
        font-weight: 300;
        margin: 10px 0;
        font-size: 30px;
      }
    }
  }
  .page1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .page1_item {
      font-weight: 700;
      img {
        width: 20%;
      }
      padding: 64px;
      width: 25%;
      min-width: 300px;
      color: #ffffff;
      .title {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  .page_button {
    // width: 100%;
    margin: 20px;
    .button {
      width: 60%;
      font-size: 22px;
      margin: auto;
      padding: 15px 10px;
    }
  }
  .page2 {
    margin-top: 40px;
    width: 100%;
    .title {
      text-align: center;
      color: #29293a;
    }
    .container {
      width: 60%;
      margin: 0 auto;
    }
    .offer_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        min-width: 300px;
        width: 30%;
        text-align: center;
        padding: 10px;
        p {
          text-align: left;
          color: #828590;
          font-size: 16px;
        }
      }
    }
  }
  .page3 {
    padding: 30px;
    background-image: url(../../../../public//assets/HkMathImg/home-bg.jpg);
    .heading {
      color: #ffffff;
    }
    .programs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 40px 18%;
    }
    .programs_item {
      background-color: #ffffff;
      width: 30%;
      min-width: 300px;
      margin-bottom: 20px;
      border-radius: 20px;
      margin: 10px;
      overflow: hidden;
      img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* 平滑过渡 */
        transform-origin: center center; /* 放大原点居中 */
      }
      img:hover {
        transform: scale(1.1);
      }
      h6 {
        color: #60cd96;
        padding: 0 10px;
      }
      p {
        color: #828590;
        padding: 10px;
      }
    }
  }
  .page4 {
    margin-top: 40px;
    .teacher_box {
      display: flex;
      justify-content: space-evenly;
      .swperItem {
        margin: 2.5rem 0;
        width: 30%;
        box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        padding: 0px 4px 8px;
        background-color: #fff;
        border-radius: 10px;
        img {
          text-align: center;
          margin: auto;
          border-radius: 0.5rem;
          box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        }
        h3 {
          text-align: center;
        }
      }
    }
    .teacher_box_mob {
      display: none;
    }
  }
  .page5 {
    max-width: 1200px;
    margin: auto;
  }
  .page6 {
    .questions_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 70%;
      margin: auto;
      .questions_item {
        width: 30%;
        // box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        border-left: 1px solid #9793972a;
        padding: 10px;
        margin: 0 10px 20px;
        min-width: 200px;
        h6 {
          font-size: 20px;
          line-height: 1.5;
          font-weight: 500 !important;
        }
        p {
          color: #828590;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .HkMathListPage {
    .page1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .page1_item {
        img {
          width: 20%;
        }
        padding: 25px;
        width: 50%;
        min-width: 300px;
        color: #ffffff;
      }
    }
  }
}
@media (max-width: 768px) {
  .HkMathListPage {
    .head_title {
      justify-content: flex-start;
      .head_item {
        display: none;
      }
      .head_icon {
        display: block;
      }
    }
    .page {
      .page_text {
        position: absolute;
        color: #ffffff;
        top: 14vh;
        margin-left: 5%;
        font-size: 20px;
        h2 {
          color: #fff;
          font-size: 20px;
          margin-bottom: 0px;
          font-weight: 700;
          text-shadow: #6880c9 0.5px 1px;
        }
        .title {
          font-weight: 300;
          font-size: 18px;
          margin: 0;
          text-shadow: #6880c9 0.5px 0.5px;
        }
        .button {
          width: 140px;
          padding: 5px 10px;
          background-color: #60cd96;
          font-size: 16px;
          border-radius: 50px;
          margin-top: 0px;
        }
      }
    }
    .page1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .page1_item {
        text-align: center;
        padding: 10px;
        width: 100%;
        min-width: 300px;
        color: #ffffff;
        img {
          width: 12%;
        }
        p {
          padding: 0px;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 0;
          margin-top: 0;
        }
        .button-280 {
          margin: auto;
          max-width: 180px;
          height: 45px;
          display: flex;
          border-radius: 30px;
          font-size: 16px;
        }
      }
    }
    .page2 {
      .container {
        width: 90%;
        margin: 0 auto;
      }
    }
    .page3 {
      .programs {
        padding: 20px 2%;
      }
    }
    .page4 {
      .teacher_box_mob {
        display: block;
      }
      .teacher_box_pc {
        display: none;
      }
    }
    .page6 {
      .questions_box {
        width: 90%;
        .questions_item {
          h6 {
            text-align: center;
          }
          width: 80%;
        }
      }
    }
  }
}
