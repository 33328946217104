.teacherCom {
  .teacher_box {
    display: flex;
    justify-content: space-evenly;
    .swperItem {
      margin: 2.5rem 0;
      width: 30%;
      box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
      padding: 0px 4px 8px;
      background-color: #fff;
      border-radius: 10px;
      img {
        text-align: center;
        margin: auto;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
      }
      h3 {
        text-align: center;
        color: rgb(157, 135, 195) !important;
        padding: 0px 10px;
      }
      h6 {
        font-weight: 500;
        font-size: 14px;
        color: #828590 !important;
        padding: 0px 10px;
      }
    }
  }
  .teacher_box_mob {
    display: none;
  }
}
@media (max-width: 576px) {
  .teacherCom {
    .teacher_box_mob {
      display: block;
    }
    .teacher_box_pc {
      display: none;
    }
  }
}
