html,
body {
  font-size: 62.5%;
}
.HkEngListPage {
  background-color: #f4b9fd1a;
  min-height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  transition: 0.2s linear;
  .integrateWith {
    max-width: 1200px;
    margin: 0 auto;
  }
  .page_btn {
    width: 100%;
    text-align: center;
    margin: 10px 0 20px;
  }
  .page1 {
    .content {
      padding-top: 10rem;
      //   width: 50%;
      padding: 0 10px;
      h1 {
        margin-bottom: 2rem;
        font-size: 7rem;
        line-height: 96px;
        span {
          color: #dd01ff;
        }
      }
    }
    .image {
      flex: 1 1 20rem;
    }
  }
  .page2 {
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    gap: 1.5rem;
    align-items: flex-start !important;
    display: grid !important;
    .box {
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 3rem 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2.5rem;
      box-shadow: 2px 2px 10px rgb(0 0 0 / 5%);
      i {
        font-size: 5rem;
        color: #dd01ff;
      }
      h3 {
        font-size: 25px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
      }
      p {
        font-size: 2rem;
      }
    }
  }
  .page3 {
    padding: 10rem 2rem;
    .image {
      flex: 1 1 20rem;
    }
    .pt-5 {
      padding-top: 3rem !important;
    }
    h2 {
      font-size: 45px;
      line-height: 60px;
    }
    p {
      padding: 2rem 0 1.5rem;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .page4 {
    .title {
      text-align: center;
      font-weight: 700;
    }
    .calssBox {
      display: flex;
      justify-content: space-evenly;
      img {
        width: 25%; /* 确保图片在其网格单元内完全展开 */
      }
    }
    .courses_box {
      display: flex;
      flex-wrap: wrap; /* 允许 flex 项换行 */
      gap: 10px;
      margin-top: 20px;
      .courses_box_item {
        flex: 1 1 auto;
        max-width: calc(100% - 20px);
        margin: 10px;
        box-sizing: border-box;
        height: auto;
        object-fit: cover;
        width: 20%;
        min-width: 200px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        text-align: center;
        margin-bottom: 4rem;
        user-select: none;
        border-radius: 0.5rem;
        padding: 2rem 2rem 3rem;
        img {
          // height: 25rem;
          width: 50%;
          margin: auto;
        }
      }
      .title {
        font-size: 25px;
      }
      .content {
        text-align: left;
      }
    }
    .courses_box_mob {
      display: none;
    }
    .classImg_pc {
      display: block;
    }
    .classImg_mob {
      display: none;
    }
  }
  .page5 {
    .teacher_box {
      display: flex;
      justify-content: space-evenly;
      .swperItem {
        margin: 2.5rem 0;
        width: 30%;
        box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        padding: 0px 4px 8px;
        background-color: #fff;
        border-radius: 10px;
        img {
          text-align: center;
          margin: auto;
          border-radius: 0.5rem;
          box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 10px;
        }
        // h3 {
        //   text-align: center;
        // }
      }
    }
    .teacher_box_mob {
      display: none;
    }
  }

  .w-100 {
    width: 100% !important;
  }
  .gap-4 {
    gap: 1.5rem !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .content {
    flex: 1 1 30rem;
  }
  .text-center {
    text-align: center !important;
  }
  .d-flex {
    display: flex !important;
  }
  .btn {
    display: inline-block;
    margin-top: 1rem;
    border-radius: 5rem;
    background-color: #7a1dec;
    cursor: pointer;
    color: #fff;
    font-size: 1.8rem;
    padding: 1.5rem 4.5rem;
    border: 1px solid #7a1dec;
    font-weight: 500;
  }
  .btn:hover {
    background-color: #fff;
    color: #7a1dec;
    border: 1px solid #7a1dec;
  }
  img {
    width: auto\9;
    max-width: 100%;
    vertical-align: middle;
    border: none;
    outline: none;
  }
  .heading {
    margin-bottom: 3.5rem;
    text-align: center;
    color: #10006d;
    font-size: 50px;
    line-height: 66px;
    span {
      color: #dd01ff;
    }
  }
  h3 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 2.5rem;
    // text-align: center;
  }
  h6 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 1rem;
    color: #d37f25 !important;
    // text-align: center;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #10006d !important;
  }
  p {
    padding: 2rem 0 1.5rem;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media (max-width: 1200px) {
  .HkEngListPage {
    .page1 {
      .content {
        padding-top: 4rem;
        h1 {
          margin-bottom: 2rem;
          font-size: 4rem;
          line-height: 50px;
          span {
            color: #dd01ff;
            font-size: 30px;
          }
        }
      }
      .image {
        flex: 1 1 20rem;
      }
    }
  }
}
@media (max-width: 576px) {
  .HkEngListPage {
    .heading {
      margin-bottom: 3rem;
      font-size: 40px;
      line-height: 66px;
    }
    .integrateWith {
      max-width: 90%;
      margin: 0 auto;
    }
    .page1 {
      .content {
        //   width: 50%;
        padding-top: 5rem;
        h1 {
          margin-bottom: 2rem;
          font-size: 4rem;
          line-height: 60px;
          span {
            color: #dd01ff;
            font-size: 30px;
          }
        }
      }
      .image {
        flex: 1 1 20rem;
      }
    }
    .page2 {
      .box {
        padding: 1rem 5rem;
        gap: 5rem;
        box-shadow: 2px 2px 10px rgb(0 0 0 / 5%);
        i {
          font-size: 4rem;
          color: #dd01ff;
        }
        h3 {
          font-size: 20px;
          margin-bottom: 0.5rem;
          font-weight: 500;
          line-height: 1.2;
        }
        p {
          font-size: 2rem;
        }
      }
    }
    .page3 {
      .pt-5 {
        font-size: 24px;
      }
    }
    .page4 {
      .calssBox {
        display: none;
      }
      .classImg_mob,
      .courses_box_mob {
        display: block;
      }
      .classImg_pc,
      .courses_box_pc {
        display: none;
      }
    }
    .page5 {
      .teacher_box_mob {
        display: block;
      }
      .teacher_box_pc {
        display: none;
      }
    }
  }
}
