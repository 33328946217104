.App {
    width: 100%;
    height: 100% ;
    color: #474747;
    font-family: Source Han Sans CN, Source Han Sans CN;
    /* position: absolute; */
  }
  #root{
    height:100%;
    min-height: 100%;
    overflow: visible;
    /* position: relative; */
  }
 
  html,
  body {
    background-color: #fff;
    margin: 0;
    padding: 0;
    /* font-family: "Lucida Calligraphy", cursive, serif, sans-serif; */
    /* font-family: "宋体"; */
    height: 100%;
    width: 100%;
    
  }
  
  input {
    outline: none;
    -webkit-appearance: button;
    -webkit-appearance: none;
    border-radius: 0;
    background: none;
    border: none;
    text-align: center;
  }
  /**
   *@Author: hy-zhangb
   *Date: 2017-05-25 11:49
   *@Last Modified by: zhangb
   *@Last Modified time: 2017-05-25 11:49
   *Email: lovewinders@163.com
   *File Path: //
   *@File Name: tueasy-jiaoguan
   *@Description: sass mixin fn
   */
  /*reset common css*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* font-family: "Hiragino Sans GB", "Helvetica Neue", Helvetica, Arial, Verdana, "Microsoft Yahei", sans-serif; */
    font-weight: 400;
    letter-spacing: 0;
    /*text-shadow: 0 1px 1px #f2f2f2;*/
  }
  
  html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  a{
    text-decoration: none;
    color: #FFF;
  }
  a:hover{
    font-weight: 800;
  }
  html,
  body {
    background: #FFF;
    color: #333333;
    
    font: 12px/1.2 "Hiragino Sans GB", "Helvetica Neue", Helvetica, Arial, Verdana, "Microsoft Yahei", sans-serif;
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
  }
  
  body {
  
    min-height: 100%;
    user-select: none;
    /* overflow: hidden; */
    /*cancel check state*/
  
  }
  
  .Main {
    width: 100%;
    height: 100%;
  }
  
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    /* font-family: inherit; */
    vertical-align: baseline;
    box-sizing: border-box;
  }
  
  ul li,
  li,
  ul,
  li ul li {
    list-style: none;
    list-style-type: none;
  }
  
  input,
  button,
  select,
  textarea {
    font: inherit;
  }
  
  /***inherit body font***/
  img {
    width: auto\9;
    max-width: 100%;
    vertical-align: middle;
    border: none;
    outline: none;
  }
  
  table {
    border-collapse: collapse;
    /* 边框合并属性  */
    border-spacing: 0;
  }
  
  /* a {
    color: #373737;
    text-decoration: none;
    cursor: pointer;
  } */
  
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
  /*针对Firefox*/
  input[type='text'] {
    vertical-align: middle;
    font-size: 12px;
  }
  
  input[type=text]::-ms-clear {
    display: none;
  }
  
  /*去掉IE 11 的文本框里的X号*/
  a,
  input,
  button,
  select,
  textarea {
    outline: medium none;
    margin: 0;
    font-size: 100%;
  }
  
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
  }
  
  label,
  select,
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"],
  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
  }
  
  button,
  input[type='submit'] {
    padding: 7px 10px;
    background: #FFF;
    border: none;
  }
  
  textarea,
  input:not([type="image"]) {
    box-sizing: border-box;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section {
    display: block;
  }
  
  audio,
  canvas,
  video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
  
  /***reset placeholder color***/
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #666;
  }
  
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #666;
  }
  
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #666;
  }
  
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #666;
  }